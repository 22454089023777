import { ResourcesConfig } from "@aws-amplify/core";

const config: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolId: "us-west-2_ogoCfUbkw",
            userPoolClientId: "ur6va78u4rrlvhkf3b122aqms",
            loginWith: {
                oauth: {
                    domain: "gru-web-prod.auth.us-west-2.amazoncognito.com",
                    redirectSignIn: [window.location.origin],
                    redirectSignOut: [window.location.origin],
                    responseType: "code",
                    scopes: ["openid"]
                }
            }
        }
    }
}

export default config;